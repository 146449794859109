import React from 'react'
import { navigate } from 'gatsby'

import { isLoggedIn } from '../../../common/utils/auth'
import { Button, Seo } from '../../common'
import { AuthFlowWrapper } from '../layout'
import { Paths } from '../../../constants/structure'

import { ButtonProps } from '../../common/button/types'

import * as landingStyles from './landing.module.scss'

const APP_COMPANY = process.env.APP_COMPANY

const Landing = () => {
   if (isLoggedIn()) {
      navigate(Paths.DASHBOARD)
   }

   const buttonProps: ButtonProps = {
      minWidth: '100%',
      type: 'link',
      variant: 'secondary',
   }

   return (
      <AuthFlowWrapper
         description={
            <>
               With {APP_COMPANY}, easily know where your tools are. Track your
               neighbor or coworker that borrowed your drill last month. Plus,
               find who has the tool you need. It's easy. Get started today!!!
            </>
         }
         heading={<>Welcome to {APP_COMPANY}</>}
      >
         <Seo title="Login" />
         <div className={landingStyles.buttonWrapper}>
            <Button {...buttonProps} href={Paths.LOGIN}>
               Sign In
            </Button>
            <Button {...buttonProps} href={Paths.HOW_IT_WORKS} external>
               Learn More
            </Button>
         </div>
      </AuthFlowWrapper>
   )
}

export default Landing
